<template>
  <div id="booking">
    <div class="container">
      <app-bar
        title="Tes Pemeriksaan"
        previousLink="/"/>

      <div class="row pt-md-3">
        <div class="mb-4">
          <div class="d-flex justify-content-between align-items-center" :class="{ 'd-none':showSearch }">
            <div class="dropdown w-100 me-2">
              <button class="w-100 dropdown-toggle bg-neutral-100 border-0 d-flex justify-content-between align-items-center" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown">
                <span class="fs-12 color-neutral-500">
                  {{ activeItem.nama }}
                </span>
              </button>
              <div class="dropdown-menu bg-neutral-200 w-100">
                <button v-for="fpp in fpps" :key="fpp.id" class="dropdown-item"
                  :class="{'active': fpp.id == activeItem.id}"
                  @click="setActive(fpp.id, fpp.nama_group_fpp, fpp.icon)"
                >
                  <span class="">{{ fpp.nama_group_fpp }}</span>
                </button>
              </div>
            </div>
            <button class="d-flex align-items-center justify-content-center btn bg-neutral-200 btn-search" @click="showSearch = true">
              <img src="@/assets/icon/search-outline.svg" alt="Ikon pencarian">
            </button>
          </div>
          <div v-show="showSearch" class="input-group">
            <input
              type="text"
              class="form-control form-search"
              v-model="keyword"
              placeholder="Cari Layanan..."
              @change="prepareSearch()">
            <span class="input-group-text form-search" role="button" @click="showSearch = false"><i class="ri-close-line"></i></span>
          </div>
        </div>
        <div
          id="service-details"
          class="col-12 col-md-8 fixed-height">
          <loading v-if="(loading.fppDetails || loading.search) && !fppDetails.length"/>
          <empty v-else-if="!fppDetails.length" :title="'TIdak ada hasil ditemukan'" :message="'Maaf layanan yang anda cari tidak ditemukan, silahkan coba layanan lainnya.'"/>
          <div v-else class="d-flex flex-column border-bottom-light">
            <div class="d-flex flex-row justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <img :src="activeItem.icon" :alt="'Ikon' + activeItem.nama" class="icon-category me-2">
                <span class="fs-18 fw-700 color-neutral-900">{{ activeItem.nama }}</span>
              </div>
            </div>
            <div v-for="detail in fppDetails" :key="detail" class="py-2">
              <div
                class="service d-flex flex-row justify-content-between align-items-center"
                :class="{
                  'active': tempCart.find(_temp => _temp.id_dt === detail.id_dt)
                }"
                role="button"
                @click="$router.push({
                  name: 'FppDetail',
                  params: { id: getIdFpp(detail), kode: detail.kode }
                })">
                <div>
                  <span class="fs-14 fw-600 color-neutral-700">{{ detail.nama }}</span>
                </div>
                <div>
                  <button class="btn btn-toggle bg-transparent p-0" @click.stop="selectFpp(detail)">
                    <img src="@/assets/icon/icon-plus.svg" class="add" alt="Ikon tambah">
                    <img src="@/assets/icon/icon-close.svg" class="remove" alt="Ikon hapus">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="booking-summary">
            <div class="card card-summary-order border-0">
              <div class="row justify-content-center pt-2">
                <div class="tab-line bg-neutral-300" />
              </div>
              <div
                class="card-body pb-1 d-flex flex-md-column"
                :class="{
                  'flex-row justify-content-between': !isSummaryForMobileShown,
                  'flex-column': isSummaryForMobileShown
                }">
                <div class="d-flex flex-row align-items-start justify-content-between py-md-2">
                  <div
                    class="d-flex align-items-center gap-2"
                    :class="{
                      'mb-3': isSummaryForMobileShown
                    }">
                    <div class="icon-wrapper">
                      <img
                        src="@/assets/icon/cart.png"
                        alt=""
                        class="summary-icon"
                      >
                    </div>
                    <div>
                      <p class="ringkasan-pemesanan fw-400 fs-10 lh-12 color-neutral-600 mb-1">
                        Ringkasan Pemesanan
                      </p>
                      <strong class="total-service fw-700 fs-12 lh-14 color-neutral-900">
                        {{ tempCart?.length }} layanan terpilih
                      </strong>
                    </div>
                  </div>
                  <div
                    :class="{
                      'd-none d-md-block': !isSummaryForMobileShown
                    }">
                    <button class="btn btn-delete bg-gray-6" @click="clearCart">
                      <p class="fst-normal mb-0 text-gray-3 fw-600 fs-14 fm-nunito">Hapus Semua</p>
                    </button>
                  </div>
                </div>
                <section
                  :class="{
                    'd-none d-md-block': !isSummaryForMobileShown
                  }">
                  <div
                    v-for="(summary) in Object.keys(summaries)" :key="summary" class="group d-flex flex-column">
                    <div class="py-2">
                      <p class="fst-normal text-gray-1 fw-600 fs-14 mb-0 fm-poppins">
                        {{ summary }}
                      </p>
                    </div>
                    <div v-for="detail in summaries[summary]" :key="detail" class="py-2">
                      <p class="fst-normal text-gray-3 fs-14 mb-0 fm-nunito">
                        <button class="btn" @click="selectFpp(detail)">
                          <i class="text-danger ri-indeterminate-circle-line"></i>
                        </button>
                        {{ detail.nama }}
                      </p>
                    </div>
                  </div>
                </section>
                <div>
                  <div
                    class="divider pt-3"
                    :class="{
                      'd-none d-md-block': !isSummaryForMobileShown
                    }">
                    <hr class="border bg-gray-6 my-0">
                  </div>
                  <div
                    class="py-2"
                    :class="{
                      'd-none d-md-block': !isSummaryForMobileShown
                    }">
                    <div class="d-flex flex-row align-items-center justify-content-between py-2">
                      <div>
                        <p class="text-gray-3 fs-14 lh-19 mb-0">
                          Total Layanan
                        </p>
                      </div>
                      <div>
                        <p class="text-gray-1 fs-14 fw-600 fm-poppins mb-0">
                          {{ tempCart?.length }} Layanan
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="pb-3 d-flex flex-row-reverse">
                    <router-link
                      class="btn py-2 d-md-none ms-2"
                      :class="{
                        [`btn-${isSummaryForMobileShown ? 'light' : 'primary'}`]: true
                      }"
                      :to="{
                        query: { showSummary: !isSummaryForMobileShown }
                      }">
                      <i
                        :class="{
                          [`ri-arrow-${isSummaryForMobileShown ? 'down' : 'up'}-s-line`]: true
                        }"
                      />
                    </router-link>
                    <button
                      class="btn btn-primary w-100 py-2 flex-grow-1"
                      :class="{
                        'd-none d-md-block': !isSummaryForMobileShown
                      }"
                      @click="prepareToCart">
                      Lanjutkan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, reactive, ref, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { showNotif } from '@/core/utility'
import { selectedBranch } from '@/core/subDivisions'
import { fpps, fppDetails, getFpps, getFppDetails, search, loading } from '@/core/fpp'
import { addToCart, cart, getCart, removeFromCart } from '@/core/cart'
import AppBar from '@/components/AppBar.vue'
import Loading from '@/components/utility/Loading.vue'
import Empty from '@/components/utility/Empty.vue'
import { title } from '@/core/page'
import { activeService, services } from '@/core/service'

export default {
  components: {
    AppBar,
    Loading,
    Empty
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const tempCart = ref([])
    const activeItem = reactive({
      nama: 'Kategori',
      id: '',
      icon: ''
    })
    const keyword = ref(null)
    const showSearch = ref(false)

    const summaries = computed(() => {
      const temp = []
      tempCart.value.map((cart) => {
        if (!temp[cart.nama_group_fpp]) {
          temp[cart.nama_group_fpp] = [cart]
        } else {
          temp[cart.nama_group_fpp].push(cart)
        }
      })
      return temp
    })

    const isSummaryForMobileShown = computed(() => route.query.showSummary === 'true')

    const setActive = async (id, nama, icon) => {
      try {
        keyword.value = ''
        activeItem.id = id
        activeItem.nama = nama
        activeItem.icon = icon

        nextTick(() => {
          title.value = activeItem.nama || 'Tes Pemeriksaan'
        })

        try {
          document.querySelector('#service-details')
            .scrollTo({ top: '0px', behavior: 'smooth' })
        } catch {}

        await getFppDetails({
          cabang: selectedBranch.value.id,
          id_fpp: id
        })
      } catch (error) {
        // Trigger toast
        showNotif({
          status: 'error',
          message: error.response.data.message
        })
      }
    }

    const updateCart = async () => {
      // Kosongkan dulu tempcart
      tempCart.value = []

      // Ambil data cart dari online
      await getCart()
      for (let i = 0; i < cart.value.arr_paket.length; i++) {
        if (cart.value.arr_paket[i].id_fpp !== null) {
          tempCart.value.push(cart.value.arr_paket[i])
        }
      }
    }

    const selectFpp = async (item) => {
      // cek sudah login belum ?
      // toggle pesanan
      const itemIndex = tempCart.value.findIndex(_item => _item.id_dt === item.id_dt)
      const namaGroup = item.nama_group_fpp ?? activeItem.nama
      if (itemIndex > -1) {
        await removeFromCart(tempCart.value[itemIndex])
      } else {
        const result = await addToCart({
          id_dt: item.id_dt,
          kode: item.kode,
          nama: item.nama,
          harga: item.harga,
          group: namaGroup
        })

        let message = result.message
        if (message === 'login') {
          message = 'Silahkan login terlebih dahulu'
          router.push({
            name: 'Login'
          })

          // Trigger toast
          showNotif({
            status: 'error',
            message: message
          })
        }
      }

      // Get data terbaru setelah proses
      await updateCart()
    }

    const prepareToCart = () => {
      if (tempCart.value.length !== 0) {
        // Arahkan ke halaman selanjutnya
        router.push({
          name: window.innerWidth < 580 ? 'Keranjang' : 'Checkout'
        })
      }
    }

    const clearCart = async () => {
      for (let i = 0; i < tempCart.value.length; i++) {
        if (tempCart.value[i].id_fpp !== null) {
          await removeFromCart(tempCart.value[i])
        }
      }
      await updateCart()
    }

    const prepareSearch = async () => {
      if (keyword.value === '') {
        await setActive(fpps.value[0].id, fpps.value[0].nama_group_fpp)
      } else {
        await search(keyword.value)
        activeItem.nama = 'Hasil Pencarian'
        activeItem.id = ''
      }
    }

    const getIdFpp = (item) => {
      if (activeItem.id) {
        return activeItem.id
      }

      return fpps.value.find(_fpp => _fpp.nama_group_fpp === item.nama_group_fpp).id
    }

    onMounted(async () => {
      activeService.value = services.value.find(
        _service => parseInt(_service.id_group) === 7
      )

      await getFpps()
      const fppActive = fpps.value.find(_fpp => parseInt(_fpp.id) === parseInt(route.params.id)) || fpps.value[0]
      await setActive(fppActive.id, fppActive.nama_group_fpp, fppActive.icon)

      // Refresh cart
      await updateCart()
    })

    return {
      loading,
      fpps,
      fppDetails,
      keyword,
      activeItem,
      setActive,
      selectFpp,
      summaries,
      tempCart,
      prepareToCart,
      isSummaryForMobileShown,
      prepareSearch,
      clearCart,
      showSearch,
      getIdFpp
    }
  }
}
</script>

<style lang="scss" scoped>
.service-category {
  border-radius: 10px;
  position: relative;

  img {
    width: 2.5rem;
    aspect-ratio: 1.05/1;
    object-fit: cover;
    flex: none;

    @media (min-width: 521px) {
      width: 2rem;
    }
  }

  &.active{
    background: #D5F6FF;
    border-radius: 10px;
  }

  .badge-count {
    @media (max-width: 520px) {
      position: absolute;
      top: 1rem;
      right: 1rem;
      transform: translate(50%, -50%);
    }

    background: #F2994A;
    border-radius: 4px;
  }
}

.form-search {
  border: 1px solid #CBD5E0;
  background: white;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 8px 8px 8px 14px;
  border-right: none;
}

.input-group-text.form-search {
  border-right: 1px solid #CBD5E0;
  border-radius: 100px;
  border-left: none;
  background: white;
}

.service {
  padding: 8px;
  border-radius: 10px !important;

  .remove {
    display: none;
    color: white;
  }

  &.active {
    background: #EDF2F7;

    .add {
      display: none;
    }

    .remove {
      display: block;
    }
  }
}

.btn-next {
    border-radius: 8px;
}

.fixed-height {
  height: calc(100vh - 14rem);
  overflow-y: scroll;
}

.booking-summary {
  @media (max-width: 520px) {
    position: fixed;
    bottom: 0rem;
    left: 0;
    right: 0;
    box-shadow: 0rem 0rem 1.5rem rgba(0, 0, 0, 0.2);
    transition: all .3s ease-in-out;
    max-height: 93vh;

    section {
      max-height: 61vh;
      overflow-y: auto;
    }
  }

  .card-summary-order {
    border-radius: 10px !important;
    box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.05);

    .tab-line {
      width: 48px;
      height: 4px;
      border-radius: 14px;
    }

    .ringkasan-pemesanan {
      @media (max-width: 520px) {
        color: rgb(179, 179, 179);
      }
    }
  }
}

.icon-category {
  width: 30px;
  height: 30px;
}

.border-bottom-light {
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
}

.form-control:focus {
  box-shadow: none;
}

.dropdown {
  .dropdown-toggle {
    padding: 8px 8px 8px 14px;
    border-radius: 100px;
  }

  .dropdown-toggle::after {
    font-size: 25px;
    color: #4A5568;
  }
}

.dropdown-menu {
  max-height: 250px;
  overflow-y: scroll;
  border-radius: 10px;
}

.btn-search {
  padding: 8px;

  img {
    width: 18px;
    height: 18px;
  }
}
</style>
